.header {
  height: 70px;
  background-color: #00915a;
}

.header * {
  color: white
}

.header p {
  margin: 0;
  font-size: 24px;
  font-weight: 600;
}

.iconContainer {
  height: 50px;
  width: 50px;
  border: 2px solid white;
  border-radius: 50px;
  margin-right: 5px;
  overflow: hidden
}