@import url("https://fonts.googleapis.com/css2?family=Dosis:wght@200;300;400;500;600;700;800&display=swap");
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.5/font/bootstrap-icons.css");

* {
  font-family: "Dosis", sans-serif;
}

/* Tables global style for the app */
.wide-container {
  width: 90%;
  margin: 20px auto;
}

.table-container thead {
  background-color: #00915a;
  color: white;
}

.table-container thead th {
  display: table-cell;
  vertical-align: middle;
}

.table-container input,
.table-container select {
  width: auto;
  display: inline;
  margin-left: 10px;
}

.table-container thead tr {
  border-color: white;
}

.table-container tr {
  border-color: #00915a;
}

/* Buttons global style for the app */
.btn {
  font-size: 15px;
}

.ask-link {
  padding: 0;
  background-color: transparent !important;
  border-color: transparent !important;
  text-decoration: underline;
  color: #0d6efd !important;
}

.btn-link:active:focus {
  background-color: white !important;
  border: white !important;
}

.btn-primary,
.btn:active:focus {
  background-color: #00915a;
  border-color: #00915a;
}

.btn-primary:hover {
  background-color: #2e611e;
}

.btn-outline-primary {
  border-color: #00915a;
  color: #00915a;
}

.btn-outline-primary:hover {
  background-color: #00915a;
  border-color: #00915a;
  color: white;
}

.btn:disabled {
  background-color: #00915a;
  border-color: #00915a;
  opacity: 0.5;
}

.btn-lg {
  min-width: 150px;
}

/* Icons as background for buttons */
/* If green button wanted, icon hexa is #1FB141 and bg hexa is #00915A, if blue button wanted both icon and background hexa are #0D6EFD  */
.upload-icon {
  background: url("./assets/images/upload.svg") no-repeat center / contain;
}

.upload-icon:hover {
  background: url("./assets/images/upload-hover.svg") #00915a no-repeat center /
    contain;
}

.download-icon {
  background: url("./assets/images/download.svg") no-repeat center / contain;
}

.download-icon:hover {
  background: url("./assets/images/download-hover.svg") #00915a no-repeat center /
    contain;
}

.back-icon {
  background: url("./assets/images/return.svg") no-repeat center / contain;
}

.back-icon:hover {
  background: url("./assets/images/return-hover.svg") #00915a no-repeat center /
    contain;
}

.delete-icon {
  background: url("./assets/images/delete.svg") no-repeat center / contain;
}

.delete-icon:hover {
  background: url("./assets/images/delete-hover.svg") #00915a no-repeat center /
    contain;
}

.file-icon {
  background: url("./assets/images/file.svg") no-repeat center / contain;
}

.file-icon:hover {
  background: url("./assets/images/file-hover.svg") #00915a no-repeat center /
    contain;
}

.archive-icon {
  background: url("./assets/images/archive-folder.svg") no-repeat center /
    contain;
}

.archive-icon:hover {
  background: url("./assets/images/archive-folder-hover.svg") #00915a no-repeat
    center / contain;
}

.dlreport-icon {
  background: url("./assets/images/file-download.svg") no-repeat center /
    contain;
}

.dlreport-icon:hover {
  background: url("./assets/images/file-download-hover.svg") #00915a no-repeat
    center / contain;
}

/* Form style */
.form-control:focus {
  box-shadow: none;
  border-color: #3a913f;
}

/* Icons size */
.bi-hourglass-split {
  font-size: 1.5rem;
}

.bi-person {
  font-size: 2.5em;
}

/* Pagination */
a.page-link,
.page-link:hover {
  color: black;
}

.active > .page-link,
.page-link.active {
  background-color: #00915a;
  border: none;
  color: white;
}

.page-link:focus {
  box-shadow: none;
}

.page-item:focus {
  border: none;
}

/* Checkmark after dowload doc */
.checkmark {
  display: inline-block;
  transform: rotate(45deg);
  height: 20px;
  width: 12px;
  border-bottom: 5px solid #00915a;
  border-right: 5px solid #00915a;
}
